import { AutoGenerator } from "./components/AutoGenerator"

export const App = () => {
  return (
    <>
      <AutoGenerator />
    </>
  )
}

