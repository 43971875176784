import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useRef, useState } from 'react'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { InputTextarea } from 'primereact/inputtextarea'
import { Panel } from 'primereact/panel'
import axios from 'axios'

const linkExamples = [
  {
    link: 'https://itea.ua/en/about-our-company',
    output: `A webpage about ITEA, an IT Education Academy, providing services in the CIS and Canada markets. They have established ITEAHub and ITEA branches in other Ukrainian cities and are preparing to enter the Chinese market with AI implemented into the learning process. #ITEA #ITeducation.`
  },
  {
    link: 'https://link.springer.com/chapter/10.1007/978-3-031-36805-9_42',
    output: `This page appears to be a conference paper in the field of computational science and its application. The paper discusses a comparative analysis of community detection and transformer-based approaches for topic clustering of scientific papers. #computationalscience #topicclustering`
  },
  {
    link: 'https://medium.com/@saurabhsingh_23777/grammar-chunking-and-text-information-extraction-140cd796d73b',
    output: `A tutorial article about grammar chunking and text information extraction using the Python library spaCy. The article explains the steps involved in extracting meaningful information from text using grammar chunking and provides examples and code snippets to demonstrate the process. #NLP #Python`
  },
  {
    link: 'https://www.primefaces.org/primeflex',
    output: `This page appears to be a technical reference guide for web developers, focusing on various topics related to web page layout, design, and styling. Some hashtags to describe this site could be #webdevelopment #css`
  },
  {
    link: 'https://www.youtube.com/watch?v=Bx9gHuWCnT8',
    output: `This page is about finding the ideal ski width for new skis. It provides information on the different ski width categories and offers recommendations on choosing the right width. #skiing #skiwidth`
  },
  {
    link: 'https://medium.com/nmc-techblog/easy-drag-and-drop-in-react-22778b30ba37',
    output: `A tutorial article explaining the basics of the open-source library react-dnd and providing code examples for drag-and-drop functionality in React. #React #DragAndDrop`
  },
  {
    link: 'https://platform.openai.com/docs/introduction',
    output: `This page is an introduction to the OpenAI API, which can be used for tasks such as understanding natural language and code, generating and editing images, and converting speech into text. It explains the key concepts of GPTs (generative pre-trained transformer) models, embeddings, and tokens. #OpenAI #API`
  },
  {
    link: 'https://towardsdatascience.com/graphgps-navigating-graph-transformers-c2cc223a051c',
    output: `This page appears to be an article discussing recent advances in graph transformers for graph machine learning tasks. It highlights the benefits and drawbacks of using graph transformers compared to traditional message passing graph neural networks. #graphtransformers #graphmachinelearning`
  },
  {
    link: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0186314',
    output: `This is a webpage from the PLOS (Public Library of Science) website. It appears to be a research article about the structure of 311 service requests as a signature of urban location. #research #urbanplanning`
  },
  {
    link: 'https://client.ucomp.net',
    output: `An article about the Urban Complexity Lab at NYU's Center For Urban Science + Progress. They leverage data science and complexity to understand and improve urban systems. #urbananalysis #datascience`
  },
  {
    link: 'http://mda.math.muni.cz',
    output: `A webpage about the MUNI Data Analytics program that connects young professionals with well-known companies. The program aims to equip future professionals with diverse skill sets and provide them with practical skills and an applied vision in data analytics. It also highlights the benefits of studying Data Science and the increasing demand for data analysts in the industry. #DataAnalytics #DataScience`
  },
]

export const AutoGenerator = () => {
  const toast = useRef(null)
  const cancelToken = useRef(null)
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [keywords, setKeywords] = useState([])
  const [content, setContent] = useState('')

  const [systemContent, setSystemContent] = useState('You are a system for recognizing the topic of web pages by webpage code.')
  const [userContent, setUserContent] = useState('Generate 3 sentences to describe the meaning of this website page using the content. Write only the answer.')
  const [charsLimit, setCharsLimit] = useState(7000)

  const [link, setLink] = useState('')

  const clearInfoBlock = () => {
    setDescription('')
    setContent('')
  }

  const generate = async () => {
    setLoading(true)
    clearInfoBlock('')

    cancelToken.current = axios.CancelToken.source()

    const url = `${process.env.REACT_APP_API_URL}/api/content/auto-generate`

    try {
      const config = {
        method: 'post',
        url: url,
        data: { link, mode: 'TEST_MODE' },
        // Отмена запроса
        cancelToken: cancelToken.current?.token
      }

      const response = await axios(config)

      if (response.status === 200) {
        const { description = '', abstract, keywords } = response.data
        setDescription(description)
        setContent(abstract)
        setKeywords(keywords)

        setLoading(false)
      }
    } catch (error) {
      if (error.code === 'ERR_CANCELED') {
        setLoading(false)
        return false
      }

      setLoading(false)
      clearInfoBlock('')

      console.log(error)

      const { message } = error.response.data

      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: 3000
      })
    }
  }

  const clear = () => {
    clearInfoBlock('')
    setLink('')
  }

  const cancelRequest = () => {
    cancelToken.current?.cancel()
  }

  return (
    <>
      <div className="flex flex-column justify-content-center align-items-center auto-generator">
        <h1 className='logo-title'>
          bookmark.red description generator (beta)
        </h1>

        <div className='w-23rem flex flex-column gap-2'>

          {/* <label>System:</label>
        <InputTextarea 
          value={systemContent} 
          onChange={(e) => setSystemContent(e.target.value)} 
          rows={3} cols={30} />

        <label>User:</label>
        <InputTextarea 
          value={userContent} 
          onChange={(e) => setUserContent(e.target.value)} 
          rows={3} cols={30} />

        <label>Website content chars limit:</label>
        <InputText 
          value={charsLimit} 
          onChange={(e) => setCharsLimit(e.target.value)} 
          className='p-inputtext-sm'
          keyfilter="int" 
          placeholder="Integers" /> */}

          <div className='flex relative'>
            <InputText
              placeholder='Website link'
              className='w-full p-inputtext-sm link'
              value={link}
              onChange={(e) => setLink(e.target.value)} />

            {
              loading ? (
                <i
                  className='clear circle-element pi pi-spin pi-spinner absolute'></i>
              ) : (
                link
                  ? (
                    <i
                      onClick={clear}
                      disabled={loading || !link.trim()}
                      className='clear circle-element pi pi-times absolute'></i>
                  ) : ''

              )
            }
          </div>

          <div className='flex gap-2'>
            {
              loading
                ? (
                  <Button
                    onClick={cancelRequest}
                    className='get-description p-button-sm p-button p-component p-button-outlined p-button-warning w-full'
                    label="Cancel request" />
                )
                : (
                  <Button
                    onClick={generate}
                    disabled={loading || !link.trim()}
                    className='get-description p-button-sm font-normal bg-green-600 border-green-600 generate-btn w-full'
                    label="Get description" />
                )
            }
          </div>

          {
            loading
              ? <><Skeleton height="4rem"></Skeleton> <Skeleton height="4rem"></Skeleton></>
              : (
                <>
                  {
                    description ? (
                      <>
                        <label className='special'>Keywords: </label>
                        <div className='flex flex-wrap gap-2 keywords'>
                          {
                            keywords.map(keyword => {
                              return (
                                <div className='keyword-item'>
                                  {keyword}
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    ) : ''
                  }
                  {
                    description ? (
                      <>
                        <label className='special'>Output: </label>
                        <span className='overflow-hidden description-area'> {description} </span>
                      </>
                    ) : ''
                  }
                  {
                    description ? (
                      <>
                        <label className='special'>Input (website parsed content): </label>
                        <span className='overflow-hidden description-area'> {content} </span>
                      </>
                    ) : ''
                  }
                </>
              )
          }
        </div>

        {/* <div className='links flex flex-wrap justify-content-center align-items-start gap-3'>
          {
            linkExamples.map(link => {
              return (
                <Panel key={link.link} header={
                  <a href={link.link}>
                    {link.link}
                  </a>
                } className='custom-panel'>
                  <p className="m-0">
                    <b>Output:</b>
                    {
                      link.output
                    }
                  </p>
                </Panel>
              )
            })
          }
        </div> */}

        <Toast ref={toast} />
      </div>
    </>
  )
}